<template>
    <div>
        <div class="box">
            <h2>{{ $t('gocardless.bank_accounts') }}</h2>

            <template v-if="enable_add_mandate">
                {{ $t('gocardless.add_mandate') }}<br>
                <button :disabled="loading_add_mandate" class="btn btn-primary" type="button" @click.prevent="add_mandate()">
                    <font-awesome-icon v-if="loading_add_mandate" :icon="['far', 'spinner']" spin />
                    {{ $t('global.ajouter') }}
                </button>
            </template>

            <LoadingSpinner class="col-12" v-if="loading" />
            <template v-else>
                <CustomTable
                    id_table="gocardless_bank_account"
                    :items="bank_accounts"
                    sortBy="account_holder_name"
                    primaryKey="id"
                    :hasExportOption="false"
                    :showPagination="false"
                    :display_action_button="false"
                    :checkboxes="false"
                />
            </template>
        </div>
    </div>
</template>

<script type="text/javascript">
    import Gocardless from '@/mixins/Gocardless.js'
    
    export default {
        name: "GocardlessBankAccount",
        mixins: [Gocardless],
        data () {
            return {
                loading: false,
                enable_add_mandate: false,
                loading_add_mandate: false,
                bank_accounts: null
            }
        },
        mounted() {
            this.init_component()
        },
        methods: {
            async init_component() {
                this.loading = true
                this.bank_accounts = await this.getBankAccounts()
                if(!this.bank_accounts) {
                    this.enable_add_mandate = true
                }

                this.loading = false
            },
            async add_mandate() {
                this.loading_add_mandate = true
                const mandate = await this.addMandate()
                if(mandate) {
                    window.location = mandate.redirect_url
                } else {
                    this.loading_add_mandate = false
                }
            },
        },
        components: {
            LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
		    CustomTable : () => import('GroomyRoot/components/Table/CustomTable')
        }
    }
</script>
